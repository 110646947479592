export default {
  yes: 'Sim',
  no: 'Não',
  all: 'Todos',
  sendEmail: 'Enviar e-mail',
  export: 'Exportar',
  selected: '1 Selecionado | {n} Selecionados',
  confirm: 'Confirmar',
  next: 'Avançar',
  cancel: 'Cancelar',
  close: 'Fechar',
  back: 'Voltar',
  refresh: 'Atualizar',
  add: 'Adicionar',
  send: 'Enviar',
  save: 'Salvar',
  saveAndSend: 'Salvar e Enviar',
  saveAndSync: 'Salvar e Sincronizar',
  syncToErp: 'Sincronizar ERP',
  duplicate: 'Duplicar',
  reschedule: 'Reagendar',
  search: 'Pesquisar',
  delete: 'Excluir',
  download: 'Download',
  upload: 'Upload',
  loadMore: 'Carregar mais',
  ok: 'Ok',
  edit: 'Editar',
  enter: 'Entrar',
  pause: 'Pausar',

  invalidForm: 'Você tem erros no formulário',
  successfullySave: '{label} salvo com sucesso',
  successfullySent: '{label} enviado com sucesso',
  youAreSureDeleteRecord: 'Tem certeza que deseja excluir esse registro?',
  youDoNotHavePermissionToAccessTheScreen: 'Você não tem permissão para acessar a tela',

  day: 'Dia | Dias',
  week: 'Semana | Semanas',
  month: 'Mês | Meses',
  today: 'Hoje',
  yesterday: 'Ontem',
  currentMonth: 'Mês atual',
  lastMonth: 'Último mês',
  lastThreeMonths: 'Últimos 3 meses',
  lastSixMonths: 'Últimos 6 meses',
  lastYear: 'Último ano',
  currentYear: 'Ano atual',

  months: {
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro',
  },

  validations: {
    required: '{fieldName} é obrigatório',
    minLength: '{fieldName} deve ter ao menos 1 caracter | {fieldName} deve ter ao menos {n} caracteres',
    maxLength: '{fieldName} não deve ter mais que 1 caracter | {fieldName} não deve ter mais que {n} caracteres',
    email: '{fieldName} deve possuir um e-mail válido',
    phone: '{fieldName} deve possuir um telefone válido',
    cnpj: '{fieldName} deve possuir um CNPJ válido',
    min: '{fieldName} não deve ser menor que {min}',
    max: '{fieldName} não deve ser maior que {max}',
    arrayOfEmails: '{fieldName} deve conter somente emails',
    arrayLength: '{fieldName} deve conter 1 elemento | {fieldName} deve conter {n} elementos',
    minArrayLength: '{fieldName} deve conter ao menos 1 elemento | {fieldName} deve conter ao menos {n} elementos',
    maxArrayLength:
      '{fieldName} não deve conter mais que 1 elemento | {fieldName} não deve conter mais que {n} elementos',
    fileMaxSize: '{fieldName} não deve conter arquivos com tamanho menor ou igual {maxSize}KB',
  },

  // Components
  dateRangeFilter: {
    from: 'De',
    until: 'Até',
  },
  dataTableFilter: {
    title: 'Filtros',
    clickHereToFilterItems: 'Clique aqui para filtrar os itens exibidos',
    clickToHideFilters: 'Clique aqui para ocultar os filtros',
  },
  confirmationDialog: {
    header: 'Aviso!',
  },
  emailComposer: {
    from: 'Remetente',
    to: 'Destinatários',
    cc: 'Cópias',
    subject: 'Assunto',
    message: 'Mensagem',
    attachments: 'Anexos',
    iWouldLikeReceiveCopy: 'Desejo receber uma cópia deste e-mail',
    attachFiles: 'Enviar anexos',
    attachFilesHint: `Habilitando essa flag, os anexos da agenda serão enviados junto ao e-mail.
    OBS: Os anexos do campo descrição não serão enviados.`,
  },
  dragDropUpload: {
    title: 'Selecione um arquivo',
    uploadSuccessfulyFinished: 'Upload concluído com sucesso',
    waitUntilUploadFinishing: 'Aguarde até a conclusão do upload',
    errorOnUpload: 'Erro ao fazer upload',
  },
  dashboardUpload: {
    title: 'Upload de arquivo',
    uploadSuccessfulyFinished: 'Upload concluído com sucesso',
    errorOnUpload: 'Erro ao fazer upload',
  },
  audioRecorder: {
    messages: {
      permissionDenied: 'Sem permissão para usar o microfone. Habilite o uso nas configurações',
      permissionDismissed: 'Não autorizado o uso do microfone',
    },
  },
  units: {
    minutes: 'MIN',
    quantity: 'QTD',
    clients: 'CLI',
  },
};
